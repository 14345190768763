<script context="module" lang="ts">
    import type { Load } from '@sveltejs/kit';

    export const load: Load = async ({ stuff }) => {
        const query = await stuff.cms.globalContent();

        return {
            props: {
                query,
            },
        };
    };
</script>

<script lang="ts">
    import type {
        BigAdFragment,
        SmallAdFragment,
        GlobalContentQuery,
        MegaAdFragment,
    } from '$lib/../gen/graphql';
    import '../styles/app.scss';
    import PreloadingIndicator from '$lib/components/nav/PreloadingIndicator.svelte';
    import { navigating, page } from '$app/stores';
    import Footer from '$lib/components/Footer.svelte';
    import KubaparisMenu from '$lib/components/nav/KubaparisMenu.svelte';
    import { ads } from '$lib/stores';
    import SmallInserat from '$lib/ads/SmallInserat.svelte';
    import CookieBanner from '$lib/privacy-banner/CookieBanner.svelte';
    import { routes } from '$lib/routes';
    import KubaparisMobileMenu from '$lib/components/nav/KubaparisMobileMenu.svelte';
    import { shuffle } from 'lodash-es';
    import { browser } from '$app/env';
    import { fade } from 'svelte/transition';
    import { onMount } from 'svelte';

    let animate = !$navigating;
    let loaded = false;

    onMount(() => {
        // loaded = true
        console.log("navigating", $navigating)
    });

    export let query: GlobalContentQuery;

    $: submissionPages = query?.submissionPages as SubmissionPageType[];

    function buttonsappeared() {
        loaded = true;
    }

    /** Ads Stuff */
    let smallAds: SmallAdFragment[] = [];
    let bigAds: BigAdFragment[] = [];
    let megaAds: MegaAdFragment[] = [];
    $: if (query && query.ads) {
        smallAds = [];
        bigAds = [];
        megaAds = [];
        query.ads.forEach((ad) => {
            if (ad.__typename === 'ads_contentBanner_Entry') {
                bigAds.push(ad);
            } else if (ad.__typename === 'ads_sidebarBanner_Entry') {
                smallAds.push(ad);
            } else if (ad.__typename === 'ads_landingpageBanner_Entry') {
                megaAds.push(ad);
            } else {
                console.error(`Can't handle ad with __typename: ${ad?.__typename}`, ad);
            }
        });
        smallAds = smallAds;
        bigAds = bigAds;
        megaAds = megaAds;

        ads.set({
            small: shuffle(smallAds),
            big: shuffle(bigAds),
            mega: megaAds,
        });
    }
</script>


        <div in:fade={{delay: 500, duration: 5000}} class="contents">

            {#if $navigating}
                <PreloadingIndicator />
            {/if}

            {#if query?.kubaparisMenu && query.kubaparisMenu.__typename === 'menu_GlobalSet'}
                <!-- Desktop menu -->
                <header
                    class="top-0 bottom-0 left-0 right-0 z-desktop-menu hidden lg:sticky lg:block"
                    style:visibility={// hide menu on calendar page
                    $page.url.pathname === routes.calendar ? 'hidden' : 'visible'}
                >
                    <KubaparisMenu
                        content={query.kubaparisMenu}
                        postCategories={query.postCategories}
                        {submissionPages}
                        loading={!loaded}
                        on:buttons_appeared={buttonsappeared}
                    />
                </header>

                <!-- Mobile menu -->
                <KubaparisMobileMenu
                    content={query.kubaparisMenu}
                    postCategories={query.postCategories}
                    {submissionPages}
                    footer={query.footer.__typename === 'footer_GlobalSet' ? query.footer : null}
                />
            {/if}
            <main class="content-wrapper grid grid-cols-gridWithAside" class:hiddenwithopacity={!loaded && animate }>
                <slot />
                {#if $page.url.pathname === '/' && ads && $ads.small.length > 0}
                    <aside class="ml-5 hidden w-28 space-y-5 md:block lg:w-32">
                        {#each shuffle($ads.small) as ad}
                            <SmallInserat {ad} />
                        {/each}
                    </aside>
                {/if}
            </main>

            {#if query?.footer && query.footer.__typename === 'footer_GlobalSet'}
                <Footer content={query.footer} />
            {/if}

            <CookieBanner privacyPolicyLink={routes.privacyPolicy} />
        </div>


<style lang="scss">
    main {
        transition: opacity 2s ease;
        min-height: 100vh;
        opacity: 1;
        & > :global(*) {
            grid-column: main;
            // outline: 1px dashed red;
        }
        & > :global(section + section) {
            margin-top: var(--gap);
        }
    }
    aside {
        grid-column: side;
        grid-row: 1 / span 999;
    }
    .hiddenwithopacity {
        opacity: 0 !important;
    }
</style>
