<script lang="ts">
    import type { FooterFragment } from '$gen/graphql';

    export let content: FooterFragment;

    const year = new Date().getFullYear();
    let showEmailInput = false;
</script>

<footer
    class="flex min-h-[40vh] flex-col bg-black p-5 text-xl text-white dark:bg-white dark:text-black md:text-2xl"
>
    {#if content}
        <!-- Subscribe Block -->
        <section class="grid place-items-center">
            <div
                class="mt-32 mb-36 grid w-9/12 place-items-center border-b border-white dark:border-black"
            >
                {#if showEmailInput}
                    <form
                        action="https://kubaparis.us3.list-manage.com/subscribe/post?u=e6c2e760cd8ce8dce7fc1a643&amp;id=0aea809baa"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate "
                        target="_blank"
                        novalidate
                    >
                        <div id="mc_embed_signup_scroll">
                            <div class="flex flex-row">
                                <div class="mc-field-group grow">
                                    <!-- <label for="mce-EMAIL">Email <span class="asterisk ">*</span> </label> -->
                                    <input
                                        type="email"
                                        value=""
                                        placeholder="E-mail address"
                                        name="EMAIL"
                                        class="required email w-full border-0 bg-inherit placeholder:text-white md:border-none"
                                        id="mce-EMAIL"
                                        autofocus
                                    />
                                </div>
                                <div id="mce-responses" class="clear">
                                    <div
                                        class="response"
                                        id="mce-error-response"
                                        style="display:none"
                                    />
                                    <div
                                        class="response"
                                        id="mce-success-response"
                                        style="display:none"
                                    />
                                </div>
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                    <input
                                        type="text"
                                        name="b_e6c2e760cd8ce8dce7fc1a643_0aea809baa"
                                        tabindex="-1"
                                        value=""
                                    />
                                </div>
                                <div class="clear shrink">
                                    <input
                                        type="submit"
                                        value="subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe"
                                        class="button cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                {:else}
                    <button on:click={() => (showEmailInput = true)} class="text-center">
                        {content.newsletterLabel}
                    </button>
                {/if}
            </div>
        </section>

        <!-- Footer Part -->
        <section class="text-xl leading-5 md:text-2xl md:leading-6">
            <div class="flex flex-col  justify-between gap-5 sm:flex-wrap lg:flex-row">
                <div class="flex max-w-[33ch] flex-col justify-between">
                    <div>
                        <h3>About</h3>
                        <div class="space-y-5">
                            {#each content.about as entry}
                                <p>{entry.aboutText}</p>
                            {/each}
                        </div>
                    </div>
                </div>

                <div class="">
                    <h3>Follow us</h3>
                    <ul class="ml-5 flex flex-col">
                        {#each content.followUs as entry}
                            <li>
                                <a href={entry.externalLink} rel="external">{entry.label}</a>
                            </li>
                        {/each}
                    </ul>
                </div>

                <div class="flex flex-col justify-between">
                    <div>
                        <h3>Contact</h3>
                        <div class="flex flex-col">
                            {content.blogContactLabel}<a href={`mailto:${content.blogContactEmail}`}
                                >{content.blogContactEmail}</a
                            >
                            {content.studioContactLabel}
                            <a href={`mailto:${content.studioContactEmail}`}
                                >{content.studioContactEmail}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-3 flex flex-col justify-between gap-5 md:flex-row">
                <div>© {year} KUBAPARIS</div>
                <ul id="internal-links" class=" flex flex-row space-x-2">
                    {#each content.linkToSingles as link}
                        <li>
                            <a href={`/${link.uri}`}>{link.title}</a>
                        </li>
                    {/each}
                </ul>
            </div>
        </section>
    {/if}
</footer>

<style lang="scss">
    #internal-links {
        & > :not(li:last-child) > a::after {
            content: ',';
        }
    }
</style>
