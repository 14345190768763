<script lang="ts">
    import { browser } from '$app/env';
    import { localStore } from './localStore';

    export let privacyPolicyLink: string;
    export let text: string = `We use cookies. To find out more, read our`;
    export let acceptText: string = 'Allow Cookies';
    export let rejectText: string = 'Reject Cookies';
</script>

{#if $localStore.showCookieBanner && browser}
    <div
        style="filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));"
        class="fixed bottom-0 left-0 z-cookie-banner w-full md:m-5"
    >
        <article
            class="clipped fixed bottom-0 left-0 z-cookie-banner m-5 grid h-52 content-end bg-pink px-5 pb-16"
        >
            <section class="flex place-content-between space-x-5">
                <div class="inline-block">
                    {`${text} `}<a href={privacyPolicyLink} class="underline">Privacy Policy</a>
                </div>
                <div class="flex flex-wrap gap-3">
                    <button
                        on:click={() => {
                            $localStore.cookiesAllowed = false;
                            $localStore.showCookieBanner = false;
                        }}
                        class="link-button fix-font-shift whitespace-nowrap"
                        data-text={rejectText}>{rejectText}</button
                    >
                    <button
                        on:click={() => {
                            $localStore.cookiesAllowed = true;
                            $localStore.showCookieBanner = false;
                        }}
                        class="link-button fix-font-shift whitespace-nowrap"
                        data-text={acceptText}>{acceptText}</button
                    >
                </div>
            </section>
        </article>
    </div>
{/if}

<svg class="svg">
    <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"
        ><path
            d="M0.891,1 C0.851,1,0.815,0.901,0.795,0.742 H0.791 C0.772,0.901,0.735,1,0.695,1 S0.619,0.901,0.6,0.742 H0.595 C0.576,0.901,0.54,1,0.5,1 S0.424,0.901,0.405,0.742 H0.4 C0.38,0.901,0.344,1,0.305,1 S0.228,0.901,0.209,0.742 H0.204 C0.185,0.901,0.149,1,0.109,1 C0.049,1,0,0.776,0,0.5 S0.049,0,0.109,0 C0.149,0,0.185,0.099,0.204,0.258 H0.209 C0.228,0.099,0.265,0,0.304,0 S0.38,0.099,0.4,0.258 H0.405 C0.424,0.099,0.46,0,0.5,0 S0.576,0.099,0.595,0.258 H0.6 C0.619,0.099,0.656,0,0.696,0 S0.772,0.099,0.791,0.258 H0.796 C0.815,0.099,0.851,0,0.891,0 C0.951,0,1,0.224,1,0.5 S0.951,1,0.891,1"
        /></clipPath
    >
</svg>

<style lang="scss">
    .svg {
        position: absolute;
        width: 0;
        height: 0;
    }
    .clipped {
        background-size: contain;
        -webkit-clip-path: url(#my-clip-path);
        clip-path: url(#my-clip-path);
    }
</style>
